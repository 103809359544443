export default [
  {
    title: 'blindflekk - magasinkonsept',
    text: 'magazine concept about subcultures',
    year: '2020',
    category: 'magazine',
    imageSrc: 'oversikt/blindflekk.png',
  },
  {
    title: 'jorden brenner og ingen bryr seg',
    text: 'fanzine about feelings and the environment',
    year: '2020',
    category: 'fanzine',
    imageSrc: 'oversikt/jordenbrennerfansine.png',
  },
  {
    title: 'jorden brenner og ingen bryr seg 2',
    text: 'art exhibit about environment',
    year: '2020',
    category: 'exhibit',
    imageSrc: 'oversikt/sommerdag.png',
  },
  {
    title: 'Blåskjell',
    text: 'jewlery brand',
    year: '2018',
    category: 'design',
    imageSrc: 'oversikt/lotta-3.jpg',
  },
  {
    title: 'UNIform - speculative design',
    text: 'speculative fashion design',
    year: '2018',
    category: 'design',
    imageSrc: 'oversikt/uniform.png',
  },
  {
    title: 'hundre dikt om fred',
    text: 'book design for nobels fredssenter',
    year: '2019',
    category: 'design',
    imageSrc: 'oversikt/100diktomfred.png',
  },
  {
    title: 'honne live',
    text: 'concert poster design for honne',
    year: '2018',
    category: 'design',
    imageSrc: 'oversikt/honneplakat.png',
  },
];
