import { Project } from '../App';

export function getCategories(projects: Project[]) {
  let categories = ['all'];

  projects.forEach(project => {
    if (!categories.includes(project.category)) {
      categories.push(project.category);
    }
  });

  return categories;
}
