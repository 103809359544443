import React, { useState } from 'react';
import Layout from '../../components/Layout';
import Filter from '../../components/work/Filter';
import Projects from '../../components/work/Projects';
import { Project } from '../../helpers/projectType';
import projects from '../../helpers/projectsData';

const Work = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');

  return (
    <Layout>
      <section className='work'>
        <h1>work</h1>

        <Filter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          projects={projects}
        />

        <Projects projects={projects} selectedCategory={selectedCategory} />
      </section>
    </Layout>
  );
};

export default Work;
