import React from 'react';
import ProjectCard from './ProjectCard';
import { Project } from '../../App';

interface Props {
  projects: Project[];
  selectedCategory: string;
}

const Projects = ({ projects, selectedCategory }: Props) => {
  return (
    <>
      {projects.map(project => {
        if (
          selectedCategory === 'all' ||
          project.category === selectedCategory
        ) {
          return <ProjectCard key={project.title} project={project} />;
        } else {
          return null;
        }
      })}
    </>
  );
};

export default Projects;
